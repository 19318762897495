import request from '@/util/request'

export function GetTemAttendanceTask(params) {
  return request({
    url: '/TemAttendance.asmx/GetTemAttendanceTask',
    method: 'GET',
    params,
  })
}

export function GetTemAttendanceList(params) {
  return request({
    url: '/TemAttendance.asmx/GetTemAttendanceList',
    method: 'GET',
    params,
  })
}
