<template>
  <div>
    <div class="job_header"></div>
    <div class="top">
      <div class="ask_jxtit">
        <span class="ask_jxtit_name">代办任务</span>
      </div>
      <div class="index_mq" style="height: 7.4rem">
        <!--        <router-link to="news">-->
        <!--          <a class="index_mq_more" href="#">更多 &nbsp;</a>-->
        <!--        </router-link>-->

        <ul class="index_new">
          <li v-for="(item, i) in TaskList" :key="i" @click="toTaskInfo(item)">
            {{ item.Node_Title }}
          </li>
        </ul>
      </div>
    </div>
    <div v-if="isShow">
      <div class="ask_jxtit">
        <span class="ask_jxtit_name">本周网格任务</span>
      </div>
      <div class="index_mq" style="height: 4rem">
        <ul class="index_new">
          <li @click="toCellGradeTask()">
            {{ cellGradeTask }}
          </li>
          <li @click="toCellAdviseTask()">
            {{ cellAdviseTask }}
          </li>
        </ul>
      </div>
    </div>
    <div v-if="isShow">
      <div class="ask_jxtit">
        <span class="ask_jxtit_name">今日网格考勤任务</span>
      </div>
      <div class="index_mq" style="height: 4rem">
        <ul class="index_new">
          <li @click="toCellAttendance(1)">
            {{ cellAmAttendance }}
          </li>
          <li @click="toCellAttendance(0)">
            {{ cellPmAttendance }}
          </li>
        </ul>
      </div>
    </div>
    <div class="ask_jxtit">
      <span class="ask_jxtit_name">中队夜间考勤任务</span>
    </div>
    <div class="index_mq" style="height: 2.5rem">
      <ul class="index_new">
        <li @click="toTemAttendance()">
          {{ temAttendance }}
        </li>
      </ul>
    </div>
    <!--底部菜单-->
    <!--    <div class="wap_footer">-->
    <!--      <div class="index_mq" style="height:7.4rem">-->
    <!--        <a class="index_mq_name">劳务资讯</a>-->
    <!--        <router-link to="news">-->
    <!--          <a class="index_mq_more" href="#">更多 &nbsp;</a>-->
    <!--        </router-link>-->

    <!--        <ul class="index_new">-->
    <!--          <li v-for="(item, i) in newsList" :key="i" @click="toNewsInfo(item)">-->
    <!--            -->
    <!--            {{ item.title }}-->
    <!--          </li>-->
    <!--        </ul>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import { Dialog } from 'vant'
  import { getIstList } from '@/api/ist/ist/ist'
  import { GetTemAttendanceTask } from '@/api/ist/temAttendance/temAttendance'
  import { GetCellTask } from '@/api/ist/cellTask/cellTask'
  import { CellAttendanceTask } from '@/api/ist/cellAttendance/cellAttendance'
  import { checkRole } from '@/util/validate'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'Index',
    data() {
      return {
        TaskList: [],
        cellGradeTask: '',
        cellAdviseTask: '',
        cellAmAttendance: '',
        cellPmAttendance: '',
        temAttendance: '',
        isShow: false,
      }
    },
    created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      this.User_ID = userInfo.User_ID
      this.isShow = checkRole(4) || checkRole(12)
      this.fetchTaskData()
      if (this.isShow) {
        this.fetchcellTaskData()
        this.fetchcellAttendanceData()
      }
      this.fetchtemAttendanceData()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'istIndex',
        '城管-代办任务首页',
        'zzdcg.yy.gov.cn/istIndex'
      )
    },
    methods: {
      async fetchTaskData() {
        const { data } = await getIstList({
          UserID: this.User_ID,
          PageNo: this.PageNo,
          PageSize: this.PageSize,
          Title: '',
        })
        console.log(data)
        if (data.data.length > 0) {
          this.flag = true
          this.pageCount = data.pageCount
          this.TaskList = this.TaskList.concat(data.data)
          this.loading = false
        } else {
          this.flag = false
          this.loading = false //不处于加载状态
          this.isFinished = true //数据加载完，不会再处于加载状态
        }
      },
      async fetchcellTaskData() {
        const { data } = await GetCellTask({ User_ID: this.User_ID })
        this.cellGradeTask =
          '本周评分任务完成进度（' + data.PF_WCS + '/' + data.PF_RWS + '）'
        this.cellAdviseTask =
          '本周劝导任务完成进度（' + data.QD_WCS + '/' + data.QD_RWS + '）'
      },
      async fetchcellAttendanceData() {
        const { data } = await CellAttendanceTask({ User_ID: this.User_ID })
        console.log(data)
        this.cellAmAttendance =
          '上午重点店铺考勤进度（' + data.SW_YS + '/' + data.SM_NUM + '）'
        this.cellPmAttendance =
          '下午重点店铺考勤进度（' + data.XW_YS + '/' + data.SM_NUM + '）'
      },
      async fetchtemAttendanceData() {
        const { data } = await GetTemAttendanceTask({ User_ID: this.User_ID })
        this.temAttendance =
          '夜间考勤任务进度（' + data.SignNum + '/' + data.TaskNum + '）'
      },
      toTaskInfo(item) {
        console.log(item.Flow_ID)
        console.log(item.Node_ID)
        let url = ''
        if (item.Flow_ID == 84) {
          if (item.Node_ID == 2) {
            url = '/istAdministrationIssued'
            //url = '/istXzWgSp'
          } else {
            url = '/istAdministrationCell'
          }
          console.log(url)
          this.$router.push({
            path: url,
            query: {
              pkValue: Base64.encode(item.PK_Value),
              istID: Base64.encode(item.IST_ID),
              seqID: Base64.encode(item.SEQ_ID),
              nodeID: Base64.encode(item.Node_ID),
            },
          })
        } else if (item.Flow_ID == 89) {
          if (item.Node_ID == 2) {
            url = '/istEventIssued'
          } else {
            url = '/istEventCell'
          }
          this.$router.push({
            path: url,
            query: {
              pkValue: Base64.encode(item.PK_Value),
              istID: Base64.encode(item.IST_ID),
              seqID: Base64.encode(item.SEQ_ID),
              nodeID: Base64.encode(item.Node_ID),
            },
          })
        } else if (item.Flow_ID == 90) {
          if (item.Node_ID == 5) {
            url = '/istShopColorationCell'
          } else if (item.Node_ID == 6) {
            url = '/istShopColorationIssued'
          }
          this.$router.push({
            path: url,
            query: {
              pkValue: Base64.encode(item.PK_Value),
              istID: Base64.encode(item.IST_ID),
              seqID: Base64.encode(item.SEQ_ID),
              nodeID: Base64.encode(item.Node_ID),
            },
          })
        } else {
          Dialog.alert({
            title: '提示',
            message: '此流程请在电脑端审批！',
          }).then(() => {
            // on close
          })
        }
      },
      toCellAttendance(AMorPM) {
        let url = '/cellAttendance'
        this.$router.push({
          path: url,
          query: {
            AMorPM: Base64.encode(AMorPM),
          },
        })
      },
      toCellGradeTask() {
        let url = '/cellTask'
        this.$router.push({
          path: url,
          query: {},
        })
      },
      toCellAdviseTask() {
        let url = '/advise'
        this.$router.push({
          path: url,
          query: {
            type: Base64.encode(2),
          },
        })
      },
      toTemAttendance() {
        let url = '/temAttendance'
        this.$router.push({
          path: url,
          query: {},
        })
      },
    },
  }
</script>

<style scoped>
  body {
    background: #f4f4f4 !important;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    background: #f8f8f8;
    font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei;
  }
  /* em 和 i 斜体的文字不倾斜 */
  em,
  i {
    font-style: normal;
  }
  .index_mq {
    margin: 0 0.32rem;
    background: #fff;
    border-radius: 0.213333rem;
    padding: 0 0.266667rem 0.266667rem 0.266667rem;
  }
  .index_mq .k {
    width: 22%;
    float: left;
    margin-left: 2%;
    margin-top: 1%;
    height: 2.3rem;
    line-height: 1rem;
    text-align: center;
    color: #000;
    font-size: 0.43rem;
  }
  /*.index_mq .ytl { background:#1b8ffe}
.index_mq .yjs { background:#009802}
.index_mq .yzw { background:#d40da3}
.index_mq .yzt { background:#4a1cc7}*/

  .index_mq_name {
    font-weight: bold;
    font-size: 0.5rem;
    padding-left: 0.266666rem;
    padding-top: 0.266666rem;
  }
  .index_mq_more {
    right: 0.266667rem;
    top: 0.266667rem;
    font-size: 0.4rem;
    color: #999;
    float: right;
  }

  .index_new {
    margin: auto;
  }
  .index_new li {
    font-size: 0.35rem;
    padding: 0.373333rem 0 0.266667rem 0;
    border-bottom: 0.026667rem solid #eee;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .index_new img {
    width: 0.5rem;
    margin-right: 0.2rem;
    margin-left: 0.1rem;
  }

  .index_ico {
    border-radius: 0.213333rem;
    background-color: #fff;
    margin-top: 0.266666rem;
    width: 100%;
    padding: 0.266666rem 0 0 0;
    min-height: 1.2rem;
    float: left;
  }
  .index_ico .index_new_ico {
    width: 92%;
    float: left;
    border: 1px #e6e6e6 solid;
    margin-left: 4%;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .index_ico .index_new_ico img {
    width: 1rem;
    margin-right: 0.2rem;
    margin-left: 0.1rem;
    vertical-align: middle;
  }
  .index_ico .index_new_ico li {
    width: 45%;
    float: left;
    height: 1.4rem;
    line-height: 1.4rem;
    margin-left: 5%;
    font-size: 0.43rem;
    color: #000;
  }

  /*底部*/
  .wap_footer {
    position: fixed;
    width: 100%;
    height: 2.08rem;
    bottom: 0;
  }
  .wap_footerfixd {
    position: fixed;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 100;
    width: 100%;
    height: 1.813333rem;
  }
  .wap_footerbox {
    width: 100%;
    height: 1.813333rem;
    background: #fff;
    box-shadow: 0 0 0.266666rem #ccc;
  }
  .wap_footernav {
    position: relative;
    float: left;
    width: 33.33%;
    padding-top: 1.013333rem;
    text-align: center;
  }
  .wap_footernav .wap_footericon {
    position: absolute;
    top: 0.266666rem;
    left: 50%;
    width: 0.64rem;
    height: 0.64rem;
    margin-left: -0.32rem;
  }
  .wap_footernav .wap_footericon img {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  .wap_footer_name {
    font-size: 0.42rem;
    color: #181818;
  }
  .wap_footer_fb {
    position: absolute;
    top: -0.533333rem;
    left: 50%;
    width: 1.6rem;
    height: 1.6rem;
    margin-left: -0.8rem;
  }
  .job_header {
    display: flex;
    align-items: center;

    height: 1.173333rem;
    line-height: 1.173333rem;
    padding-left: 0.266667rem;
    padding-right: 0.266667rem;
    background-color: #2778f8;
    z-index: 999;
  }
  .ask_jxtit {
    padding: 0.266667rem;
    text-align: center;
  }

  .ask_jxtit_name::after {
    width: 0.4rem;
    height: 0.026667rem;
    background: #e3e3e4;
    display: inline-block;
    position: absolute;
    left: -0.693333rem;
    top: 0.266667rem;
    content: '';
  }
  ::after {
    clear: both;
  }
  .ask_jxtit_name::before {
    width: 0.4rem;
    height: 0.026667rem;
    background: #e3e3e4;
    display: inline-block;
    position: absolute;
    right: -0.693333rem;
    top: 0.266667rem;
    content: '';
  }
  元素 {
  }
  .ask_jxtit_name {
    display: inline-block;
    position: relative;
    font-size: 0.426667rem;
    font-weight: bold;
    color: #333;
  }
  .ask_new {
    margin: 0 0.32rem;
    background: #fff;
    border-radius: 0.213333rem;
    padding: 0 0.266667rem 0.266667rem 0.266667rem;
  }
</style>
